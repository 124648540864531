<template>
  <div>
    <h3 class="title white--text">Editing Default</h3>
    <v-row justify="space-between" class="ma-3">
      <delete-button :ondelete="deleteDefault" />

      <div class="d-flex">
        <g-button label="Return" @onclick="$router.push({ name: 'admin_defaults' })" />
        <save-button :disabled="syncing" :onsave="updateDefault" />
        <!-- <QuestionHover /> -->
      </div>
    </v-row>
    <DefaultForm v-model="selected" />
    <v-row justify="end">
      <ApplyAllButton :syncing="syncing" @apply="applyDefault" />
    </v-row>
  </div>
</template>

<script>
import { DefaultApi, DefaultForm, ApplyAllButton } from "@/modules/admin.defaults";
export default {
  components: {
    DefaultForm,
    ApplyAllButton,
  },
  data() {
    return {
      selected: {
        env: null,
        country: null,
        db: null,
        databaseType: null,
        field: null,
        value: null,
      },

      syncing: false,
    };
  },
  computed: {
    canSave() {
      return (
        this.selected.env &&
        this.selected.db &&
        this.selected.databaseType &&
        this.selected.field &&
        this.selected.value
      );
    },
  },
  methods: {
    applyDefault() {
      this.syncing = true;
      DefaultApi.updateDefault(this.$route.params.default_id, this.selected)
        .then((v) => DefaultApi.applyDefault(this.$route.params.default_id))
        .then((v) => {
          this.syncing = false;
          this.$root.$success("All updated");
        })
        .catch((err) => {
          this.syncing = false;
          this.$root.$error(err);
        });
    },
    updateDefault() {
      return DefaultApi.updateDefault(this.$route.params.default_id, this.selected)
        .then((v) => {
          this.$root.$success("Default added");
          this.$router.push({ name: "admin_defaults" });
        })
        .catch((err) => this.$root.$error(err));
    },
    deleteDefault() {
      return DefaultApi.removeDefault(this.$route.params.default_id)
        .then((v) => {
          this.$root.$success("Default added");
          this.$router.push({ name: "admin_defaults" });
        })
        .catch((err) => this.$root.$error(err));
    },
  },
  mounted() {
    DefaultApi.getOneDefault(this.$route.params.default_id)
      .then((data) => {
        this.selected = data.data;
      })
      .catch((err) => this.$root.$error(err));
  },
};
</script>
